import Link from 'next/link';
import { BiChevronRight } from 'react-icons/bi';
import { IoIosAdd } from 'react-icons/io';

import Button, {
  ButtonVariant,
  ButtonRadius,
  ButtonSize,
} from '@/components/Common/Button';

import { BoardProps } from '@/components/Main/Board/Board.types';

function BoardCommon({
  label,
  tabList,
  path,
  tabMenu,
  handleTabClick,
  handleOnClick,
  mode,
}: BoardProps) {
  const banPickBoard = mode === 'banPick';

  return (
    <div className="board-header-wrapper">
      <div className="board-header">
        <h1 className="title">{label}</h1>
        {banPickBoard && IS_LOGIN && (
          <Button
            onClick={handleOnClick}
            variant={ButtonVariant.White}
            radius={ButtonRadius.Oval}
            width={88}
            height={32}
          >
            <span>생성하기</span>
            <IoIosAdd />
          </Button>
        )}
      </div>
      <div className="board-util">
        <div className="tab-wrapper">
          {tabList?.map((tab: any) => {
            const isActive = tab.id === tabMenu;
            return (
              <Button
                key={tab.id}
                className={isActive ? 'active' : ''}
                label={tab.label}
                variant={ButtonVariant.Mono}
                radius={ButtonRadius.Square}
                size={ButtonSize.Small}
                onClick={handleTabClick}
                width={100}
              />
            );
          })}
        </div>
        <Link href={`${path}`} passHref>
          <a href={`${path}`} className="show-more-wrapper">
            <span className="show-more">더보기</span>
            <BiChevronRight fill="#868e96" />
          </a>
        </Link>
      </div>
    </div>
  );
}

export default BoardCommon;

const IS_LOGIN = true;
