import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import {
  BoardLayout,
  BoardRowLayout,
} from '@/components/Main/Board/Board.styled';
import BoardCommon from '@/components/Main/Board/BoardCommon';
import { BoardProps, BoardT } from '@/components/Main/Board/Board.types';
import LockIcon from '@/assets/icons/ico-lock.svg';

function BoardSimulation({
  label,
  tabList,
  path,
  tabMenu,
  data,
  mode,
  handleTabClick,
}: BoardProps) {
  const router = useRouter();

  const handleOnClick = () => {
    router.push('/simulations/create');
  };

  return (
    <BoardLayout>
      <BoardCommon
        handleOnClick={handleOnClick}
        handleTabClick={handleTabClick}
        label={label}
        tabList={tabList}
        path={path}
        tabMenu={tabMenu}
        mode={mode}
      />
      <ul className="boardRowWrap">
        {data &&
          data.map(({ id, name, created_at, is_open, blue, red }: BoardT) => (
            <BoardRowLayout key={id}>
              <div className="title-wrapper">
                <Link href="/simulations" passHref>
                  <p className="title">
                    {name}
                    <span>
                      (<strong style={{ color: '#0a84ff' }}>{blue}</strong>명 vs{' '}
                      <strong style={{ color: '#ff453a' }}>{red}</strong>명)
                    </span>
                  </p>
                </Link>
                {is_open && (
                  <span className="private">
                    <Image src={LockIcon} alt="d" width="12px" height="16px" />
                  </span>
                )}
              </div>
              <div className="post-info-wrapper">
                <div className="user">{name}</div>
                <div className="post-info-wrapper">
                  <div className="user" hidden>
                    {name}{' '}
                  </div>
                  <div className="date-time">
                    {new Date(created_at).toLocaleString()}
                  </div>
                </div>
              </div>
            </BoardRowLayout>
          ))}
      </ul>
    </BoardLayout>
  );
}

export default BoardSimulation;
