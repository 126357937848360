import jungleOn from '@/assets/images/jungle-on.png';
import jungleOff from '@/assets/images/jungle-off.png';
import topOn from '@/assets/images/top-on.png';
import topOff from '@/assets/images/top-off.png';
import midOn from '@/assets/images/mid-on.png';
import midOff from '@/assets/images/mid-off.png';
import adcOn from '@/assets/images/adc-on.png';
import adcOff from '@/assets/images/adc-off.png';
import supportOn from '@/assets/images/support-on.png';
import supportOff from '@/assets/images/support-off.png';

enum RankFilter {
  Win = 'win',
  Pick = 'pick',
  Ban = 'ban',
}

const rateFilter = [
  { label: '승률', value: RankFilter.Win },
  { label: '픽률', value: RankFilter.Pick },
  { label: '밴률', value: RankFilter.Ban },
];

enum PostFilter {
  Notices = 'notices',
  Free = 'free',
}

const postFilter = [
  { label: '공지', value: PostFilter.Notices },
  { label: '자유', value: PostFilter.Free },
];
enum BanPickFilter {
  Processing = 'processing',
  MatchComplete = 'matchComplete',
}

const banPickFilter = [
  { label: '진행중', value: BanPickFilter.Processing },
  { label: '매칭완료', value: BanPickFilter.MatchComplete },
];

const positionFilter = [
  /* FIXME: import 오류 임시 수정
  {
    label: '탑',
    iconUrlOn: topOn,
    iconUrlOff: topOff,
  },
  {
    label: '정글',
    iconUrlOn: jungleOn,
    iconUrlOff: jungleOff,
  },
  {
    label: '미드',
    iconUrlOn: midOn,
    iconUrlOff: midOff,
  },
  {
    label: '원딜',
    iconUrlOn: adcOn,
    iconUrlOff: adcOff,
  },
  {
    label: '서폿',
    iconUrlOn: supportOn,
    iconUrlOff: supportOff,
  },
  */
];

export {
  positionFilter,
  RankFilter,
  rateFilter,
  PostFilter,
  postFilter,
  BanPickFilter,
  banPickFilter,
};
