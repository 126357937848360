import styled from 'styled-components';
import {
  buttonVariant,
  buttonRadius,
  ButtonVariant,
  ButtonRadius,
} from '@/components/Common/Button';

const BoardLayout = styled.article`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% / 3);
  box-sizing: border-box;

  .boardRowWrap {
    min-height: 265px;
  }

  & + & {
    border-left: 1px solid ${({ theme }) => theme.palette.paleGray};
  }

  .board-header-wrapper {
    .board-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-create {
        padding: 10px 14px 8px 14px;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 16px;
        border: solid 1px ${({ theme }) => theme.palette.primary};
        background: ${({ theme }) => theme.palette.white};
        color: ${({ theme }) => theme.palette.primary};
        font-family: ${({ theme }) => theme.fonts.SpoqaHanSansNeo};
        font-size: ${({ theme }) => theme.fontSizes.xsmall};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        box-sizing: border-box;
      }
    }

    .title {
      color: ${({ theme }) => theme.palette.black};
      font-family: ${({ theme }) => theme.fonts.SpoqaHanSansNeo};
      font-size: ${({ theme }) => theme.fontSizes.xl};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }

    .board-util {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 86px;

      .tab-wrapper {
        display: flex;
        align-items: center;
        width: fit-content;
        ${buttonRadius[ButtonRadius.Square]};
        border: solid 1px ${({ theme }) => theme.palette.paleGray};
        background-color: ${({ theme }) => theme.palette.status.inactive};

        button {
          background-color: transparent;
          &.active {
            ${buttonVariant[ButtonVariant.Default]};
          }
        }
      }

      .show-more-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        width: 48px;
        height: 20px;
        border-bottom: solid 1px ${({ theme }) => theme.palette.lightGray};
        cursor: pointer;

        .show-more {
          position: absolute;
          left: 0;
          color: ${({ theme }) => theme.palette.lightGray};
          font-family: ${({ theme }) => theme.fonts.NotoSansKr};
          font-size: 14px;
          font-weight: ${({ theme }) => theme.fontWeights.bold};
        }

        svg {
          position: absolute;
          right: -5px;
        }
      }
    }

    .post-list {
      width: 100%;
    }
  }
`;

const BoardListLayout = styled.section`
  margin: 48px auto 0;
  padding: 52px 48px;
  width: ${({ theme }) => theme.deviceSizes.desktop};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  box-sizing: border-box;
`;

const BoardRowLayout = styled.li`
  width: 100%;
  padding: ${({ theme }) => theme.paddings.small} 0
    ${({ theme }) => theme.paddings.xsmall} 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.paleGray};
  box-sizing: border-box;
  opacity: 0.5;

  .title-wrapper {
    min-height: 20px;
    display: flex;
    align-items: center;
    .title {
      display: block;
      width: 100%;
      font-family: ${({ theme }) => theme.fonts.SpoqaHanSansNeo};
      font-size: ${({ theme }) => theme.fontSizes.small};
      cursor: pointer;
    }
    .private {
      margin: 0 ${({ theme }) => theme.margins.xsmall};
    }
    .comments {
      margin: 0 ${({ theme }) => theme.margins.xsmall};
      color: ${({ theme }) => theme.palette.comments};
    }
  }
  .post-info-wrapper {
    > * {
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
    line-height: 1.67;
    color: ${({ theme }) => theme.palette.gray};
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    font-family: ${({ theme }) => theme.fonts.SpoqaHanSansNeo};
  }
`;

export { BoardLayout, BoardListLayout, BoardRowLayout };
