import { instance } from '@/api';

export const fetchMainChampions = async () => {
  return instance.get(`/main/champions`);
};

export const fetchMainPosts = async () => {
  return instance.get(`/main/posts`);
};

export const fetchMainSimulations = async () => {
  return instance.get(`/main/simulations`);
};

