import { PercentageBarLayout } from '@/components/Main/Rank/Rank.styled';

interface RankCardRowProps {
  title: '픽률' | '승률' | '밴률';
  percentage: number | string;
}

function PercentageBar({ title, percentage }: RankCardRowProps) {
  return (
    <PercentageBarLayout percentage={percentage}>
      <p className="label title">{title}</p>
      <div className="percentagebar-container">
        <span className="pertentage" />
        <span className="bar" />
      </div>
      <p className="label percentage">{percentage}%</p>
    </PercentageBarLayout>
  );
}

export default PercentageBar;
