import styled from 'styled-components';
import {
  HeaderWrapper,
  HeaderFilterWrapper,
} from '@/components/Main/Rank/Rank.styled';
import {RankFilter, rateFilter} from '@/components/Main/Rank/filter';

interface RankHeaderProps {
  filter: string;
  onSelect: ((filter: RankFilter) => void);
}

const Filter = styled.div`
  opacity: 0.3;
  &.active {
    border-bottom: 2px solid ${props => props.theme.palette.white};
    opacity: 1;
  }
`;

function RankHeader({ filter, onSelect }: RankHeaderProps) {
  return (
    <HeaderWrapper>
      <h3 className="title">챔피언 랭킹</h3>
      <HeaderFilterWrapper>
        {rateFilter.map(({ label, value }) => (
          <Filter
            className={value === filter ? 'active' : ''}
            key={value}
            onClick={() => onSelect(value)}
          >
            <span>{label}</span>
          </Filter>
        ))}
      </HeaderFilterWrapper>
    </HeaderWrapper>
  );
}

export default RankHeader;
