import { useState } from 'react';
import styled from 'styled-components';

import RankHeader from '@/components/Main/Rank/RankHeader';
import RankCard from '@/components/Main/Rank/RankCard';

import { RankFilter } from '@/components/Main/Rank/filter';

const RankContainerLayout = styled.section`
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 360px;
    background-image: linear-gradient(to bottom, #602ff7 360px, #f9f9f9 40px);
    z-index: -1;
  }
`;

const RankWrapper = styled.article`
  margin: 0 auto;
  padding: 52px 0 20px;
  width: ${({ theme }) => theme.deviceSizes.desktop};
  box-sizing: border-box;
`;

const RankCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 218px;
`;

function RankContainer({ data }: any) {
  const [filter, setFilter] = useState<RankFilter>(RankFilter.Win);
  const championStats = data ? data.data[filter] : [];
  const onSelect = (filter: RankFilter) => {
    setFilter(filter);
  };

  return (
    <RankContainerLayout>
      <RankWrapper>
        <RankHeader filter={filter} onSelect={onSelect} />
        {/* <PositionNav position={position} onSelect={handleOnPositionSelect} /> */}
        <RankCardWrapper>
          {/* TODO: 백앤드 작업시 championStats 로 변경 */}
          {championStats.map(
            (
              { id, pick_rate, win_rate, ban_rate, champion }: any,
              i: number
            ) => {
              return (
                <RankCard
                  key={id}
                  rank={RANK[i]}
                  pickRate={pick_rate}
                  winRate={win_rate}
                  banRate={ban_rate}
                  img={`http://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion.name}_0.jpg`}
                />
              );
            }
          )}
        </RankCardWrapper>
      </RankWrapper>
    </RankContainerLayout>
  );
}

export default RankContainer;

const RANK = ['1st', '2nd', '3rd', '4th', '5th'];
