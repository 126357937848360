import styled from 'styled-components';
import PercentageBar from '@/components/Main/Rank/PercentageBar';
import { RankCardLayout } from '@/components/Main/Rank/Rank.styled';

interface RankCardProps {
  pickRate: number | string;
  winRate: number | string;
  banRate: number | string;
  img: string;
  rank: string;
}
const ContentWrapper = styled.div`
  padding: 14px 17px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

function RankCard({  pickRate, winRate, banRate, img,rank }: RankCardProps) {
  return (
    <RankCardLayout championImg={img}>
      <div className="champion-container">
          <h4 className="rank">{rank}</h4>
      </div>
      <ContentWrapper>
        <PercentageBar title="픽률" percentage={pickRate} />
        <PercentageBar title="승률" percentage={winRate} />
        <PercentageBar title="밴률" percentage={banRate} />
      </ContentWrapper>
    </RankCardLayout>
  );
}

export default RankCard;
