import styled from 'styled-components';

const HeaderWrapper = styled.div`
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;

  .title {
    font-family: ${({ theme }) => theme.fonts.SpoqaHanSansNeo};
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.palette.white};
  }
`;
const HeaderFilterWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 162px;
  font-family: ${({ theme }) => theme.fonts.SpoqaHanSansNeo};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  color: ${({ theme }) => theme.palette.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  cursor: pointer;

  .filter-wrapper.active {
    border-bottom: 2px solid ${({ theme }) => theme.palette.white};
    opacity: 1;
  }
  .filter-wrapper {
    opacity: 0.3;
  }
`;

const RankCardLayout = styled.div<{
  championImg: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 240px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.palette.white};

  .champion-container {
    position: relative;
    width: 100%;
    height: 88px;
    background-image: ${props => `url(${props.championImg})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    .rank {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      color: ${({ theme }) => theme.palette.white};
      font-family: ${({ theme }) => theme.fonts.Montserrat};
      font-size: ${({ theme }) => theme.fontSizes.xxxxl};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
`;

const PercentageBarLayout = styled.div<{
  percentage: number;
}>`
  margin: ${({ theme }) => theme.margins.small} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.black};

  .label {
    &.title {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }
    &.percentage {
      display:block;
      width:64px;
      text-align: right;
      font-family: ${({ theme }) => theme.fonts.SpoqaHanSansNeo};
      font-size: ${({ theme }) => theme.fontSizes.lg};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }

  .percentagebar-container {
    position: relative;
    width: 100px;
    height: 6px;

    .pertentage {
      position: absolute;
      width: ${props => props.percentage}%;
      height: 6px;
      border-radius: 5px;
      background: linear-gradient(to right, #a082fc, #602ff7);
      z-index: 1;
    }
    .bar {
      position: absolute;
      border-radius: 16px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.palette.paleGray};
      z-index: 0;
    }
  }
`;

export {
  HeaderWrapper,
  HeaderFilterWrapper,
  RankCardLayout,
  PercentageBarLayout,
};
