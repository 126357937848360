import styled from 'styled-components';

interface AdvertProps {
  width: string | number;
  height: string | number;
}

const AdvertLayout = styled.div`
  background: tan;
`;

function Advert({ ...props }: AdvertProps) {
  return <AdvertLayout style={{ ...props }} />;
}

export default Advert;
