import React, { useEffect, useState } from 'react';
import {
  BoardSimulation,
  BoardCommunity,
  BoardListLayout,
} from '@/components/Main/Board';
import RankContainer from '@/components/Main/Rank';
import Advert from '@/components/Advert';

import { useMainPosts } from '@/hooks/main/usePosts';
import { useMainSimulations } from '@/hooks/main/useSimulations';
import { useMainChampions } from '@/hooks/main/useChampions';

function Home() {
  const [post, setPost] = useState([]);
  const [simulation, setSimulation] = useState([]);

  const [communityTabMenu, setCommunityTabMenu] = useState(0);
  const [banPickTabMenu, setBanPicTabMenu] = useState(0);

  const champions = useMainChampions();
  const posts = useMainPosts();
  const simulations = useMainSimulations();

  useEffect(() => {
    setPost(posts.data?.data.notices);
    setSimulation(simulations.data?.data.simulations);
  }, [posts.isLoading, simulations.isLoading, champions.isLoading]);

  const postTabChange = (id: number) => {
    setCommunityTabMenu(id);
    switch (id) {
      case 0:
        return setPost(posts.data?.data.notices);
      case 1: {
        return setPost(posts.data?.data.free);
      }
      default:
        return setPost(posts.data?.data.notices);
    }
  };

  const postTab = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.textContent;
    if (id === '공지') {
      postTabChange(0);
    } else {
      postTabChange(1);
    }
  };

  const simulationTabChange = (id: number) => {
    setBanPicTabMenu(id);
    switch (id) {
      case 0:
        return setSimulation(simulations.data?.data.simulations);
      default:
        return setSimulation(simulations.data?.data.simulations);
    }
  };

  const simulationTab = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.textContent;
    if (id === '진행중') {
      simulationTabChange(0);
    } else {
      simulationTabChange(1);
    }
  };

  return (
    <>
      <RankContainer data={champions.data} />
      <BoardListLayout>
        <BoardSimulation
          mode="banPick"
          label="밴픽 시뮬레이션"
          path="/simulations"
          tabList={[]}
          tabMenu={banPickTabMenu}
          handleTabClick={simulationTab}
          data={simulation}
        />
        <BoardCommunity
          mode="posts"
          label="커뮤니티"
          path="/community"
          tabList={communityTabList}
          tabMenu={communityTabMenu}
          handleTabClick={postTab}
          data={post}
        />
        <Advert width={300} height={300} />
      </BoardListLayout>
    </>
  );
}

export default Home;

const communityTabList = [
  {
    id: 0,
    label: '공지',
  },
  {
    id: 1,
    label: '자유',
  },
];
