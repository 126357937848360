import Link from 'next/link';
import BoardCommon from '@/components/Main/Board/BoardCommon';
import {
  BoardLayout,
  BoardRowLayout,
} from '@/components/Main/Board/Board.styled';
import { BoardProps, BoardT } from '@/components/Main/Board/Board.types';

function BoardCommunity({
  label,
  tabList,
  path,
  tabMenu,
  handleTabClick,
  data,
  mode,
}: BoardProps) {
  return (
    <BoardLayout>
      <BoardCommon
        handleTabClick={handleTabClick}
        label={label}
        tabList={tabList}
        path={path}
        tabMenu={tabMenu}
        mode={mode}
      />
      <ul className="boardRowWrap">
        {data &&
          data.map(({ id, title, created_at, comments_count, type, user }: BoardT) => {
            return (
              <BoardRowLayout key={id}>
                <div className="title-wrapper">
                  <Link href={`/community/${type}/${id}`} passHref>
                    <a href={`/community/${type}/${id}`} className="title">
                      {title} <strong className="comments">({comments_count})</strong>
                      {/* TODO 게시글 공개유무 추후작업 */}
                      {/* {is_open && ( */}
                      {/*   <span className="private"> */}
                      {/*     <Image */}
                      {/*       src={LockIcon} */}
                      {/*       alt="d" */}
                      {/*       width="12px" */}
                      {/*       height="16px" */}
                      {/*     /> */}
                      {/*   </span> */}
                      {/* )} */}
                    </a>
                  </Link>
                </div>
                <div className="post-info-wrapper">
                  <div className="user">{user?.name} </div>
                  <div className="date-time">
                    {new Date(created_at).toLocaleString()}
                  </div>
                </div>
              </BoardRowLayout>
            );
          })}
      </ul>
    </BoardLayout>
  );
}

export default BoardCommunity;
